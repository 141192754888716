import React from 'react';
import ModernResume from './ModernResume';

function App() {
  return (
    <div className="App">
      <ModernResume />
    </div>
  );
}

export default App;