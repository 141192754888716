import React from 'react';
import { Phone, Mail, Github } from 'lucide-react';

const skills = [
  { name: "协调沟通", value: 9 },
  { name: "算法调研", value: 8 },
  { name: "算法工程", value: 8 },
  { name: "模型部署", value: 8 },
  { name: "算法研究", value: 8 },
  { name: "模型量化", value: 6 },
];

export default function ModernResume() {
  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <header className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white p-6 flex items-center">
          <div className="w-32 h-32 rounded-full overflow-hidden border-4 border-white shadow-lg mr-6">
            <img src="https://my-offiline-use.s3.us-east-2.amazonaws.com/test_files/senma_512x512_001.jpg" alt="马森" className="w-full h-full object-cover" />
          </div>
          <div>
            <h1 className="text-4xl font-bold mb-2">马森</h1>
            <p className="text-xl font-bold">大模型技术架构师</p>
            <div className="flex mt-4 space-x-4">
              <div className="flex items-center">
                <Phone size={18} className="mr-2" />
                <strong>18600658557</strong>
              </div>
              <div className="flex items-center">
                <Mail size={18} className="mr-2" />
                <strong>ma.sen@foxmail.com</strong>
              </div>
              <div className="flex items-center">
                <Github size={18} className="mr-2" />
                <strong>https://github.com/marson666</strong>
              </div>
            </div>
          </div>
        </header>

        <main className="p-8 space-y-8">
          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">工作经验</h2>
            <ul className="list-disc list-inside text-gray-700">
              <li>4.5年，昆仑万维  算法工程师, 2020.07 - 至今</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">教育背景</h2>
            <ul className="list-disc list-inside text-gray-700">
              <li>中国科学院大学, 计算机应用技术, 硕士, 2017.09 - 2020.07</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">项目经历</h2>
            <div className="space-y-6">
              <div>
                <h3 className="text-xl font-semibold text-gray-700 mb-3">Linky (AI社交),  音视频多模态算法工程师, 2024.05 - 至今</h3>
                <ul className="list-disc list-inside text-gray-600 mt-2 leading-relaxed">
                  <li>
                    <strong>TTS 语音合成算法落地:</strong>
                    <ul className="list-circle list-inside ml-6 mt-1">
                      <li>【算法研究】【模型部署】充分评测并对比市面上先进的开源 TTS 算法，在质量和生成速度上取得了显著成果，成功替代微软 Azure TTS 服务，成本降低 20 倍，承接了每日千万量级调用的 APP 流量。</li>
                      <li>【模型成本】在零样本情况下，将英语语音多样性提升了 2 倍，并支持英语、日语、阿拉伯语、德语、法语、意大利语、葡萄牙语、汉语等 8 种语言。</li>
                      <li>【算法研究】【模型部署】研究了低成本、高质量的小样本 TTS 模型的训练、微调和部署，为英语市场的精品语音合成功能实现商业化收入。</li>
                      <li>【模型部署】【算法工程】研究并实现了语音通话模式下的低延迟 AI 实时语音交互功能，已在产品大规模落地，服务 50 万+ DAU。</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Talking Head 音频驱动的说话人算法落地:</strong>
                    <ul className="list-circle list-inside ml-6 mt-1">
                      <li>【算法研究】基于音频驱动，实现了针对单个对象的训练和实时音频驱动 Talking Head 功能。</li>
                      <li>【算法研究】实现了基于单张图片的零样本说话视频生成算法功能效果。</li>
                    </ul>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="text-xl font-semibold text-gray-700 mb-3">SkyReels (AI漫画), 文生图算法工程师, 2023.01 - 2024.04</h3>
                <ul className="list-disc list-inside text-gray-600 mt-2 leading-relaxed">
                  <li>【模型部署】【算法工程】主导开发了业界领先的文生图模型推理服务架构，支持 10+ 种推理管线（一致性算法、文生图、图生图、图像编辑、LoRA、一键漫画等），服务支持生图用户 10 万+，总生图数 290 万+。</li>
                  <li>【算法研究】实现了首版支持 2-4 人多人同框功能，panel 非关键区域还原度从 32% 提升到 70%。</li>
                  <li>【算法研究】通过 LoRA 训练算法优化，实现了原神角色 98% 的复刻度。</li>
                  <li>【算法研究】通过 FastComposer 垫图一致性算法，外貌一致率从 88% 提升至 90%，穿着一致率从 40% 提升至 70%。</li>
                  <li>【算法研究】采用 ControlNet-Pose 模型，在三个模型架构上的生成准确率均达到业界最先进水平。</li>
                  <li>【算法研究】研发多画风自研模型，个性化画风占用户选择 60%。</li>
                  <li>【模型部署】【算法工程】负责文生图模型服务IO速度和图片压缩优化，大幅降低文生图整体链路耗时。</li>
                  <li>【模型加速】负责文生图模型算法加速，SD-1.5 模型角色生成总耗时降低 2.5 秒，SD-2.1 模型生成总耗时降低 6 秒。</li>
                  <li>【模型加速】负责一键漫画加速，生成总耗时降低 20 秒。</li>
                </ul>
              </div>

              <div>
                <h3 className="text-xl font-semibold text-gray-700 mb-3">奇点智源（昆仑天工）, 视觉算法工程师, 2021.09 - 2023.01</h3>
                <ul className="list-disc list-inside text-gray-600 mt-2 leading-relaxed">
                  <li>【算法研究】从零搭建了驱动 3D 虚拟人物面部和肢体运动的算法工程，实现了算法落地。</li>
                  <li>【算法研究】负责图像/视频/音频驱动 3D 人脸表情和动作、2D 图片/视频驱动 3D 人体说话/跳舞姿态、音乐驱动 3D 虚拟人跳舞等核心算法研发。</li>
                </ul>
              </div>

              <div>
                <h3 className="text-xl font-semibold text-gray-700 mb-3">Opera News, 推荐算法工程师, 2020.07 - 2021.09</h3>
                <ul className="list-disc list-inside text-gray-600 mt-2 leading-relaxed">
                  <li>【算法研究】【算法工程】参与新闻推荐算法项目，优化 GBDT 特征分桶，内存占用减少 50%，推理延迟降低 10%。</li>
                  <li>【算法研究】【算法工程】优化排序模型，德国和美国市场份额增长 10%，卸载率降低 1%。</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">职业技能</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {skills.map((skill, index) => (
                <div key={index} className="flex items-center">
                  <span className="w-24 text-gray-700">{skill.name}</span>
                  <div className="flex-1 bg-gray-200 rounded-full h-2.5 ml-2">
                    <div
                      className="bg-blue-600 h-2.5 rounded-full"
                      style={{ width: `${(skill.value / 10) * 100}%` }}
                    ></div>
                  </div>
                  <span className="ml-2 text-gray-600">{skill.value}</span>
                </div>
              ))}
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}

